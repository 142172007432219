import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import scrollToSection from 'utils/scrollToSection';

export const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
  const [favsLength, setFavsLength] = useState(0);
  const [isNavHidden, setIsNavHidden] = useState(false);
  const [isSidenavVisible, setIsSidenavVisible] = useState(false);
  const [initialSearchParam, setInitialSearchParam] = useState('');

  let prevScroll = 0;

  const handleScroll = e => {
    const window = e.currentTarget;

    if (prevScroll > window.scrollY) {
      setIsNavHidden(false);
    } else if (window.scrollY >= 100) {
      setIsNavHidden(true);
    }
    prevScroll = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', e => handleScroll(e));
    };
  }, []);

  useEffect(() => {
    const isBrokenGoogleLink = window.location.hash.includes('?mtm_source');

    if (isBrokenGoogleLink) {
      const indexOfQuestionMark = window.location.hash.indexOf('?');
      const anchorSubstring = window.location.hash.substring(
        0,
        indexOfQuestionMark
      );
      const mtmSubstring = window.location.hash
        .substring(indexOfQuestionMark)
        .replace('?', '&');

      const fixedInitialSearchParam = `${window.location.search}${mtmSubstring}`;
      setInitialSearchParam(fixedInitialSearchParam);

      setTimeout(() => {
        scrollToSection(anchorSubstring, 'instant');
      }, 1);
      return;
    }

    setInitialSearchParam(window.location.search);

    const indexOfHashMark = window.location.hash.indexOf('#');
    if (indexOfHashMark === -1) return;
    const anchorSubstring = window.location.hash.substring(indexOfHashMark);
    setTimeout(() => {
      scrollToSection(anchorSubstring, 'instant');
    }, 1);
  }, []);

  const hideNav = () => {
    if (window.scrollY >= 100) setIsNavHidden(true);
  };

  const closeSidenav = () => {
    if (isSidenavVisible) {
      setIsSidenavVisible(false);
      setTimeout(hideNav, 800);
      document.body.style.overflow = 'auto';
    }
  };

  const openSidenav = () => {
    if (!isSidenavVisible) {
      setIsSidenavVisible(true);
      document.body.style.overflow = 'hidden';
    }
  };

  return (
    <NavigationContext.Provider
      value={{
        isNavHidden,
        isSidenavVisible,
        hideNav,
        closeSidenav,
        openSidenav,
        favsLength,
        setFavsLength,
        initialSearchParam,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NavigationProvider;
